import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.isOpen = false
  }

  clickOutsideHandler (e) {
    if (!this.element.contains(e.target)) {
      this.element.classList.remove('active')
      this.isOpen = false
    }
  }

  toggleActive () {
    this.element.classList.toggle('active', !this.isOpen)
    this.isOpen = !this.isOpen
  }
}
