import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

export default class extends Controller {
  static targets = [
    'input',
    'option',
    'results',
    'value'
  ]

  static values = {
    path: String,
    searchKey: { type: String, default: 'q' },
    contentType: { type: String, default: 'text/x.autocomplete+html' },
    resetInputOnSelection: { type: Boolean, default: false },
    minLength: { type: Number, default: 0 }
  }

  connect () {
    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute('autocomplete', 'off')
    this.inputTarget.setAttribute('spellcheck', 'false')

    this.mouseInResults = false

    this.debouncedFetchResult = debounce(this.fetchResults.bind(this), 300)

    this.inputTarget.addEventListener('focus', this.openResults.bind(this))
    this.inputTarget.addEventListener('input', this.debouncedFetchResult)
    this.resultsTarget.addEventListener('mousedown', this.preventResultsClose.bind(this))
    this.inputTarget.addEventListener('blur', this.closeResults.bind(this))
  }

  disconnect () {
    this.inputTarget.removeEventListener('focus', this.openResults)
    this.inputTarget.removeEventListener('input', this.debouncedFetchResult)
    this.resultsTarget.removeEventListener('mousedown', this.preventResultsClose)
    this.inputTarget.removeEventListener('blur', this.closeResults)
  }

  preventResultsClose () {
    this.mouseInResults = true
    this.resultsTarget.addEventListener('mouseup', () => (this.mouseInResults = false), { once: true })
  }

  fetchResults () {
    if (!this.pathValue) return

    const query = this.inputTarget.value.trim()

    if (!query || query.length < this.minLengthValue) return

    const url = new URL(this.pathValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append(this.searchKeyValue, query)
    url.search = params.toString()

    const headers = { 'X-Requested-With': 'XMLHttpRequest', Accept: this.contentTypeValue }

    this.element.dispatchEvent(new CustomEvent('loadstart'))

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.element.dispatchEvent(new CustomEvent('load'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
        this.openResults()
      })
      .catch(() => {
        this.resultsTarget.innerHTML = null
        this.element.dispatchEvent(new CustomEvent('error'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
  }

  openResults () {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute('aria-expanded', 'true')
  }

  closeResults ({ reset } = {}) {
    if (this.mouseInResults) return
    if (this.resultsTarget.hidden) return

    this.resultsTarget.hidden = true
    if (reset) this.resultsTarget.innerHTML = null

    this.inputTarget.removeAttribute('aria-activedescendant')
    this.element.setAttribute('aria-expanded', 'false')
  }

  selectOption (event) {
    event.preventDefault()

    const value = event.currentTarget.dataset.value
    const label = event.currentTarget.dataset.label || event.currentTarget.textContent

    if (this.hasInputTarget) {
      this.inputTarget.value = this.resetInputOnSelectionValue ? '' : label
    }
    if (this.hasValueTarget) this.valueTarget.value = value
    window.dispatchEvent(new CustomEvent('optionSelected', { detail: { value, label, el: event.currentTarget } }))
    this.closeResults()
  }
}
