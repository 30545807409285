import { Controller } from '@hotwired/stimulus'
import { template } from 'lodash-es'

export default class extends Controller {
  static targets = [
    'form',
    'tagHiddenInput',
    'tagList',
    'cleaner',
    'locationInput',
    'keywordInput',
    'tagTemplate'
  ]

  static values = {
    autosubmit: { type: Boolean, default: true }
  }

  connect () {
    this.tagTemplate = template(this.tagTemplateTarget.innerHTML)
  }

  tagHiddenInputTargetConnected () {
    this.cleanerTarget.hidden = false
  }

  tagHiddenInputTargetDisconnected () {
    this.cleanerTarget.hidden = this.tagHiddenInputTargets.length <= 0
  }

  clearTags () {
    this.tagListTarget.innerHTML = ''
    this.#submitForm()
  }

  focusLocationInput () {
    this.locationInputTarget.focus()
  }

  toggleNestedDropdowns (event) {
    event.currentTarget.dataset.expanded = event.currentTarget.checked
    this.element.querySelectorAll('[data-expanded=true]').forEach(el => {
      if (!el.parentElement.contains(event.currentTarget)) {
        el.checked = false
        el.removeAttribute('data-expanded')
      }
    })
  }

  addTag (event) {
    event.preventDefault()

    let target, value, name, label

    if (event.type === 'optionSelected') {
      target = event.detail.el
      value = target.dataset.value
      name = `${target.dataset.name}[]`
      label = target.dataset.label
    } else {
      if (event.currentTarget.hasAttribute('data-target')) {
        target = this.targets.find(event.currentTarget.dataset.target)
      } else {
        target = event.currentTarget
      }

      name = target.dataset.name
      value = target.dataset.value || target.value
      label = target.dataset.label || value
    }

    if (value.length === 0) return

    this.tagHiddenInputTargets.forEach(el => {
      if (!el.name.endsWith('[]') && el.name === name) el.parentElement.remove()
    })

    const similarTag = this.tagHiddenInputTargets.find(el => el.name.endsWith('[]') && el.name === name && el.value === value)

    if (similarTag) {
      similarTag.parentElement.remove()
    } else {
      this.tagListTarget.insertAdjacentHTML('beforeend', this.tagTemplate({ label, value, name }))
    }

    if (['search', 'text'].includes(target.type)) {
      target.value = ''
    }

    this.#submitForm()
  }

  removeTag (event) {
    event.stopImmediatePropagation()
    event.currentTarget.closest('button').remove()
    this.#submitForm()
  }

  #submitForm () {
    if (!this.autosubmitValue) return

    if (this.keywordInputTarget.value) {
      this.keywordInputTarget.dispatchEvent(new KeyboardEvent('keydown', {
        key: 'Enter',
        code: 'Enter',
        which: 13,
        keyCode: 13
      }))
    }

    if (this.formTarget.dataset.remote === 'true') {
      $(this.formTarget).trigger('submit.rails')
    } else {
      this.formTarget.submit()
    }
  }
}
