import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['burger', 'pane']

  static classes = ['visible']

  static values = {
    overflow: Boolean
  }

  clickOutsideHandler (e) {
    if (!this.element.contains(e.target) && this.activePane) {
      this.activePane.classList.remove(...this.visibleClasses)
      this.activePane = undefined
      this.#updateDom()
    }
  }

  toggleDropdown (event) {
    if (this.activeDropdown && this.activeDropdown !== event.currentTarget) {
      this.activeDropdown.checked = false
    }
    this.activeDropdown = event.currentTarget
  }

  togglePane (event) {
    if (event.currentTarget.dataset.menuTarget === 'burger' && this.activePane) {
      this.activePane.classList.remove(...this.visibleClasses)
      this.activePane = undefined
    } else {
      const paneId = event.params.paneId

      if (paneId === this.activePane?.dataset.paneId) {
        this.paneTargets.forEach(el => {
          el.classList.remove(...this.visibleClasses)
        })
        this.activePane = undefined
      } else {
        this.paneTargets.forEach(el => {
          if (el.dataset.paneId === paneId) {
            this.activePane = el
            el.classList.add(...this.visibleClasses)
          } else {
            el.classList.remove(...this.visibleClasses)
          }
        })
      }
    }
    this.#updateDom()
  }

  #updateDom () {
    this.element.classList.toggle('active', this.activePane)

    if (this.hasBurgerTarget) {
      this.burgerTarget.classList.toggle('active', this.activePane)
      this.burgerTarget.classList.toggle('text-white', this.activePane !== undefined && this.activePane.dataset.paneId !== 'root')
    }

    if (this.overflowValue) {
      document.body.classList.toggle('overflow-hidden-body', this.activePane)
      document.documentElement.classList.toggle('overflow-hidden-html', this.activePane)
    }
  }
}
